<template>
  <div class="wrap" :class="tradeMethod">
    <van-nav-bar :title="''" left-arrow @click-left="$router.back()" />
    <div class="title">{{ $t(`p2p.orderDetails.${tradeMethod}Title`) }}</div>
    <div class="warning"
      v-html="$t(`p2p.orderDetails.${tradeMethod}Hint`, { '__': `<span style='color: #01BD8D;'>${format(countDownTime)}</span>` })">
    </div>
    <div class="hr"></div>
    <div class="coinInfo">
      <div class="title_bar">
        <img v-if="coinType" :src="require(`@/assets/images/user_pay/${coinType.toLowerCase()}.png`)" alt="">
        <div class="tradeMethod">{{ tradeMethodText }}</div>
        <div class="coinType">{{ coinType }}</div>
      </div>
      <div class="line">
        <div class="label">{{ $t('p2p.orderDetails.label.totalAmount') }}</div>
        <div class="value">
          <span class="num">
            <span class="amount">{{ amount }}</span>&nbsp;
            <!-- <span class="unit">{{ userInfo.currency_code }}</span> -->
            <span class="unit">{{ moneyType }}</span>
          </span>
          <span class="iconfont icon-copy" @click="copy(amount)"></span>
        </div>
      </div>
      <div class="line">
        <div class="label">{{ $t('p2p.orderDetails.label.pricePerUnit') }}</div>
        <!-- <div class="value">{{ `${toMoney(1)} ${userInfo.currency_code}` }}</div> -->
        <div class="value">{{ $money(detailData.price || "0.00", true, "C$") }}</div>
      </div>
      <div class="line">
        <div class="label">{{ $t('p2p.orderDetails.label.quantity') }}</div>
        <div class="value">{{ `${toCoin(amount)} ${coinType}` }}</div>
        <!-- <div class="value">{{ `${amount} ${coinType}` }}</div> -->
      </div>
      <div class="line">
        <div class="label">{{ $t('p2p.orderDetails.label.counterpaty') }}</div>
        <div class="value">{{ detailData.connect_name }}</div>
      </div>
    </div>
    <div class="payInfo">
      <div class="title">{{ $t("p2p.orderDetails.payInfo_title") }}</div>
      <div class="line">
        <div class="label">{{ $t("p2p.orderDetails.label.payMethod") }}</div>
        <div class="value payMethod_value">
          <!-- <img class="bank_img" src="@/assets/images/user_pay/bank_old.png" alt=""> -->
          <i class="iconfont icon-yinhangqia"></i>
          <span class="text">{{ detailData.trade_type }}</span>
        </div>
      </div>
      <div class="line">
        <div class="label">{{ $t("p2p.orderDetails.label.fullName") }}</div>
        <div class="value">
          <span class="name">{{ detailData.trade_fullname }}</span>
          <span class="iconfont icon-copy" @click="copy(detailData.trade_fullname)"></span>
        </div>
      </div>
      <div class="line">
        <div class="label">{{ $t("p2p.orderDetails.label.email") }}</div>
        <div class="value">
          <span class="email">{{ detailData.trade_email }}</span>
          <span class="iconfont icon-copy" @click="copy(detailData.trade_email)"></span>
        </div>
      </div>
      <div class="line">
        <div class="label">{{ $t("p2p.orderDetails.label.phone") }}</div>
        <div class="value">
          <span class="email">{{ detailData.trade_phone }}</span>
          <span class="iconfont icon-copy" @click="copy(detailData.trade_phone)"></span>
        </div>
      </div>
    </div>
    <div class="bottom_bar">
      <div class="content">
        <van-button block color="#F55555" style="border-radius: 4px;" @click="toService">
          {{ $t(tradeMethodText == 'Buy' ? "p2p.orderDetails.btn_service" : "p2p.orderDetails.btn_sell") }}
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { NavBar, Button, Toast } from "vant";
import { $post, $get, dealError } from "@/utils";
import keepDigits from "@/utils/keepDigits";
import tradeMethods from "./tradeMethods.json";
import { mapGetters } from "vuex";
import webUni from '@/utils/web-view.js'
export default {
  components: {
    [NavBar.name]: NavBar,
    [Button.name]: Button
  },
  data() {
    this.initCountDownMinute = 30;
    return {
      detailData: {},
      countDownTime: this.initCountDownMinute * 60 * 1000,
      interval: null,
      tradeMethodText: "",
      coinType: "",
      tradeMethod: "",
      coinId: "",
      amount: "",
      moneyType:""
    }
  },
  computed: {
    ...mapGetters(["userInfo", "coins", "unitData"])
  },
  mounted() {
    const { type, coinType, id, amount, moneyType } = this.$route.query;
    const typeText = tradeMethods.find(item => item.value === type).title;
    this.tradeMethodText = this.$t(typeText);
    this.coinType = coinType;
    this.tradeMethod = type;
    this.coinId = id;
    this.amount = amount;
    this.moneyType = moneyType
    this.getData(id, moneyType);
    this.interval = setInterval(() => {
      this.countDownTime -= 1000;
    }, 1000)
  },
  beforeDestroy() {
    clearInterval(this.interval);
    this.interval = null;
  },
  methods: {
    async getData(id, code) {
      try {
        const { ret, msg, data } = await $get("/ptop/lists", { id, code });
        this.detailData = data;
      } catch (error) {
        dealError(error)
      }
    },
    format(val) {
      const mUnit = 60 * 1000;
      const m = Math.floor(val / mUnit);
      const s = Math.floor((val % mUnit) / 1000);
      return `${keepDigits(m, 2)}:${keepDigits(s, 2)}`
    },
    // toCoin(val) {
    //   const unitItem = this.unitData.find(item => item.code === this.userInfo.currency_code);
    //   const coinItem = this.coins.find(item => item.value === this.coinType);
    //   if (unitItem && coinItem) {
    //     const usdtAmount = val * unitItem.rate;
    //     const coinAmount = usdtAmount / coinItem.rate;
    //     return this.fixed(coinAmount);
    //   }
    //   return "";
    // },
    // toMoney(val) {
    //   const unitItem = this.unitData.find(item => item.code === this.userInfo.currency_code);
    //   const coinItem = this.coins.find(item => item.value === this.coinType);
    //   if (unitItem && coinItem) {
    //     const usdtAmount = val / unitItem.rate;
    //     const moneyAmount = usdtAmount * coinItem.rate;
    //     return this.fixed(moneyAmount);
    //   }
    //   return "";
    // },
    toCoin(val) {
      return this.fixed(val / this.detailData.price).toFixed(2);
    },
    toMoney(val) {
      return this.fixed(val * this.detailData.price).toFixed(2);
    },
    copy(text) {
      this.$copyText(text).then((e) => {
        Toast(this.$t("copySuccess"));
      });
    },
    toService() {
      const { platform } = this.$store.state.init.urlObj
      console.log(platform);
      if (platform == 'im') {
        webUni.postMessage({
          data: this.detailData.account
        })
      } else {
        location.href = this.detailData.kefu_url;
      }
    }
  }
}
</script>

<style lang="less" scoped>
.wrap {
  @comMargin: calc(13rem / 16);
  background-color: var(--nav-background);

  >.title {
    margin: @comMargin;
    padding-right: 20%;
    font-size: calc(24rem / 16);
    color: var(--theme);
  }

  .warning {
    margin: @comMargin;
    // padding-right: 20%;
    font-size: calc(14rem / 16);
    color: var(--btnsizeColor);
  }

  .hr {
    margin: @comMargin;
    height: 1px;
    background-color: var(--theme);
  }

  .coinInfo {
    margin: 0 @comMargin;
    background-color: var(--bg);
    padding: calc(15rem / 16);
    border-radius: calc(10rem / 16);
    margin-bottom: calc(15rem / 16);

    .title_bar {
      display: flex;
      align-items: center;
      font-size: calc(15rem / 16);

      >img {
        border-radius: 50%;
        width: calc(20rem / 16);
      }

      .tradeMethod {
        margin: 0 5px;
        color: #FF3030;
      }

      .coinType {
        color: var(--btnsizeColor);
      }
    }

    .line {
      margin-top: calc(12rem / 16);
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: calc(14rem / 16);

      .label {
        color: var(--theme);
      }

      .value {
        max-width: 65%;
        color: var(--btnsizeColor);

        .num {
          font-size: calc(16rem / 16);

          .unit {
            font-size: calc(14rem / 16);
          }
        }

        .email {
          word-break: break-all;
          // max-width: 60%;
        }

        .iconfont {
          color: var(--btnsizeColor);
          margin-left: 8px;
        }
      }
    }
  }

  .payInfo {
    .coinInfo();

    .title {
      // margin-top: calc(22rem / 16);
      color: var(--theme);
    }

    .payMethod_value {
      display: flex;
      align-items: center;

      .bank_img {
        width: calc(20rem / 16);
        margin-right: calc(8rem / 16);
      }

      >.iconfont {
        color: #FFB628 !important;
        font-size: calc(20rem / 16);
      }
    }
  }

  .bottom_bar {
    position: fixed;
    bottom: calc(30rem / 16);
    overflow: hidden;
    width: 100vw;
    max-width: 750px;

    .content {
      margin: @comMargin;
      // /deep/ .van-button{
      //   background-color: var(--theme) !important;
      //   color: var(--timeColor);
      // }
    }
  }
}
</style>